body {
    background: #eee !important;
}

.mantine-1cwg1aj {
    color: #898989 !important;
    font-size: 12px;
}

.nextui-c-hWZRae {
    border-radius: 0px !important;
    border: 1px solid rgb(229, 231, 235) !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
}

/*.bg-white {*/
/*    background: black !important;*/
/*}*/

/*.border,.border-b,.border-t,.border-l,.border-r {*/
/*    border-color: rgba(255,255,255,0.1) !important;*/
/*}*/

/*.bg-green-50 {*/
/*    background: rgba(0, 128, 0, 0.26);*/
/*}*/

/*.bg-blue-50 {*/
/*    background: #212121;*/
/*}*/